import request from '@/utils/request'

export function uploadImage(data) {
  return request.post('/api/v1/backend/images', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'X-FORWARDED-PROTO': 'https',
    },
  })
}
