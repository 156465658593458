<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-row>
      <v-col
        cols="12"
        md="9"
      >
        <v-card>
          <v-card-text
            class="py-9 px-8 d-flex flex-wrap justify-space-between flex-column flex-sm-row"
          >
            <div
              class="mb-8 mb-sm-0"
            >
              <div class="d-flex align-center mb-6">
                <span class="text--primary font-weight-bold text-xl">
                  <span>商户: </span>
                  {{ row.merchant.name }}
                </span>
              </div>
              <p class="mb-2 d-flex justify-space-between">
                <span>所属组: </span>
                <span class="font-weight-semibold">{{ row.merchant.group_name }}</span>
              </p>
              <p class="mb-2 d-flex justify-space-between">
                <span>价目表: </span>
                <span class="font-weight-semibold">{{ row.merchant.price_list_name }}</span>
              </p>
            </div>
            <div>
              <p class="font-weight-medium text-xl text--primary mb-4"> ID#: {{ row.order_no }}</p>
              <p class="mb-2 d-flex justify-space-between">
                <span>下单时间: </span>
                <span class="font-weight-semibold">{{ row.created_at | dateFormat }}</span>
              </p>
              <p class="mb-2 d-flex justify-space-between">
                <span>订单状态: </span>
                <v-chip
                  :color="statusMap[row.status].color"
                  label
                  small
                >
                  {{ statusMap[row.status].label }}
                </v-chip>
              </p>
              <p class="mb-2 d-flex justify-space-between">
                <span>产品数量: </span>
                <span class="font-weight-semibold">{{ row.qty }}</span>
              </p>
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text
            class="py-9 px-8 d-flex flex-wrap justify-space-between flex-column flex-sm-row"
          >
            <div
              class="mb-8 mb-sm-0"
            >
              <p class="font-weight-semibold payment-details-header"> 收货地址:</p>
              <p class="mb-1">{{ row.merchant.username }}</p>
              <p class="mb-1">{{ row.merchant.province_name +  row.merchant.city_name + row.merchant.county_name}}</p>
              <p class="mb-1">{{ row.merchant.address}}</p>
              <p class="mb-0">{{ row.merchant.mobile}}</p>
            </div>
            <div>
              <p class="font-weight-semibold payment-details-header"> 支付凭证: </p>
              <v-img
                :src="row.payment_bill || 'https://dummyimage.com/200/f5f5f5/9e9e9e&text=none'"
                max-height="200"
                max-width="200"
                min-width="200"
                min-height="200"
                style="border: 1px"
              ></v-img>
            </div>
          </v-card-text>
          <v-data-table
            v-model="selectItems"
            :headers="itemsHeaders"
            :items="row.items"
            :show-select="row.status === 3 || row.status === 4"
            selectable-key="ship_selectable"
            disable-sort
            disable-pagination
            hide-default-footer
          >
            <template #item.image="{ item }">
              <v-img
                :src="item.variant.cover_image || item.goods.cover_image"
                max-height="32"
                max-width="32"
              ></v-img>
            </template>
            <template #item.sku="{ item }">
              <router-link :to="{ name: 'goodsDetail', query: { id: item.goods.id }}">
                {{ item.variant.sku ||  item.goods.sku}}
              </router-link>
            </template>
            <template #item.name="{ item }">
              {{ item.goods.name }}
            </template>
            <template #item.variant="{ item }">
              <v-chip
                v-for="o in item.variant.option_values"
                :key="o.id"
                x-small
              >
                {{ o.label }}
              </v-chip>
            </template>
            <template #item.price="{ item }">
              <span>{{ item.price | amountFormat }}</span>
            </template>
            <template #item.total="{ item }">
              {{ (item.price * item.qty) | amountFormat }}
            </template>
            <template #item.shipment="{ item }">
              <v-chip
                :color="!item.ship_selectable ? 'success' : 'secondary'"
                label
                x-small
              >
                {{ !item.ship_selectable ? '已发货' : '待发货' }}
              </v-chip>
              <p
                v-if="!item.ship_selectable"
                class="mb-0 text-caption"
              >
                {{ item.shipment }}
              </p>
            </template>
          </v-data-table>
          <v-card-text class="py-9 px-8">
            <div class="d-flex justify-end flex-column flex-sm-row">
              <div>
                <table class="w-full">
                  <tr>
                    <td class="pe-16">合计:</td>
                    <th class="text-right">{{ row.total | amountFormat }}</th>
                  </tr>
                </table>
              </div>
            </div>
          </v-card-text>
          <v-data-table
            :headers="shipmentHeaders"
            :items="row.shipments"
            no-data-text="无发货记录"
            disable-sort
            disable-pagination
            hide-default-footer
          >
            <template #item.created_at="{ item }">
              {{ item.created_at | dateFormat }}
            </template>
            <template #item.tracking_no="{ item }">
              <v-btn
                v-if="item.tracking_no"
                text
                color="primary"
                class="px-0"
                @click="gotoKuaiDi(item)"
              >
                {{ item.tracking_no }}
              </v-btn>
            </template>
            <template #item.img="{ item }">
              <v-img
                lazy-src="https://dummyimage.com/32x32/f5f5f5/9e9e9e.png"
                max-height="32"
                max-width="32"
                :src="item.img || 'https://dummyimage.com/32x32/f5f5f5/9e9e9e.png'"
                style="cursor: pointer"
                @click="viewTrackingImage(item)"
              ></v-img>
            </template>
          </v-data-table>
          <v-divider class="py-9 px-8"></v-divider>
          <v-data-table
            :headers="refundHeaders"
            :items="row.refunds"
            no-data-text="无退款申请"
            disable-sort
            disable-pagination
            hide-default-footer
          >
            <template #item.created_at="{ item }">
              {{ item.created_at | dateFormat }}
            </template>
            <template #item.status="{ item }">
              <v-chip
                :color="refundStatusMap[item.status].color"
                label
                small
              >
                {{ refundStatusMap[item.status].color }}
              </v-chip>
            </template>
            <template #item.actions="{ item }">
              <v-btn
                v-if="item.status === 0"
                v-permission="['90002']"
                text
                color="success"
              >
                同意
              </v-btn>
              <v-btn
                v-if="item.status === 0"
                v-permission="['90002']"
                text
              >
                拒绝
              </v-btn>
            </template>
          </v-data-table>
          <v-divider class="py-9 px-8"></v-divider>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <v-card>
          <v-card-text>
            <v-btn
              v-if="row.status === 3 || row.status === 4"
              v-permission="['90002']"
              color="primary"
              class="mb-3"
              :disabled="!selectItems.length"
              block
              @click="openShipDialog"
            >
              订单发货
            </v-btn>
            <v-btn
              v-if="row.status === 1"
              v-permission="['90002']"
              outlined
              class="mb-3"
              block
              @click="operateOrder({ code: 4 })"
            >
              取消订单
            </v-btn>
            <v-btn
              v-if="row.status === 2"
              v-permission="['90002']"
              color="success"
              class="mb-3"
              block
              @click="operateOrder({ code: 1 })"
            >
              确认收款
            </v-btn>
            <v-btn
              v-if="row.status === 5"
              v-permission="['90002']"
              color="success"
              class="mb-3"
              block
              @click="operateOrder({ code: 3 })"
            >
              订单完成
            </v-btn>
            <v-btn
              v-if="row.status !== 1"
              v-permission="['90003']"
              outlined
              color="error"
              class="mb-3"
              block
              @click="deleteBtnClick"
            >
              删除订单
            </v-btn>
            <v-btn
              block
              outlined
              @click="$router.push({ name: 'orders' })"
            >
              返回
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="shipDialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          订单发货
        </v-card-title>
        <v-card-text>
          <v-select
            v-model="shippingCode"
            :items="shippingCompanies"
            item-text="name"
            item-value="code"
            label="物流公司"
            :loading="shippingCompaniesLoading"
            outlined
            dense
          ></v-select>
          <v-text-field
            v-model="trackingNo"
            label="物流单号"
            counter="50"
            outlined
            dense
            :rules="[value => (!value || value.length <= 50) || '最多输入50个字符']"
          ></v-text-field>
          <v-file-input
            v-model="trackingImg"
            accept="image/*"
            label="运单图片"
            prepend-icon=""
            :append-icon="icons.mdiFolderImage"
            persistent-hint
            show-size
            outlined
            dense
            @change="selectFile"
          ></v-file-input>
          <v-img
            lazy-src="https://dummyimage.com/300x300/f5f5f5/9e9e9e.png"
            max-height="300"
            max-width="300"
            :src="previewImage || 'https://dummyimage.com/300x300/f5f5f5/9e9e9e.png'"
            class="mb-3"
          ></v-img>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="cancelShip"
          >
            取消
          </v-btn>
          <v-btn
            color="success"
            @click="orderShip"
          >
            确认
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="deleteDialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          删除订单不会退回库存，确定要删除这个订单吗?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="cancelDelete"
          >
            取消
          </v-btn>
          <v-btn
            color="success"
            @click="deleteItem"
          >
            确认
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {
  deleteOrder,
  getOrder, operateOrder,
} from '@/api/order'

import {
  getShipping,
} from '@/api/city'

import { uploadImage } from '@/api/upload'

import {
  mdiCardBulletedOffOutline,
} from '@mdi/js'

export default {
  name: 'OrderDetail',
  data() {
    return {
      orderId: null,
      row: null,
      icons: {
        mdiCardBulletedOffOutline,
      },
      previewImage: '',
      statusMap: {
        1: {
          label: '待付款',
          color: 'warning',
        },
        2: {
          label: '待审核',
          color: 'primary',
        },
        3: {
          label: '待发货',
          color: '#03A9F4',
        },
        4: {
          label: '部分发货',
          color: '#00BCD4',
        },
        5: {
          label: '已发货',
          color: '#009688',
        },
        6: {
          label: '已完成',
          color: 'success',
        },
        7: {
          label: '已取消',
          color: 'black',
        },
        8: {
          label: '已退款',
          color: 'error',
        },
      },
      shipmentHeaders: [
        { text: 'ID', value: 'id' },
        { text: '货运公司', value: 'shipping_name' },
        { text: '运单号', value: 'tracking_no' },
        { text: '图片', value: 'img' },
        { text: '发货时间', value: 'created_at' },
      ],
      refundStatusMap: {
        0: {
          label: '待处理',
          color: 'secondary',
        },
        1: {
          label: '已同意',
          color: 'success',
        },
        2: {
          label: '已拒绝',
          color: 'error',
        },
      },
      itemsHeaders: [
        { text: '图片', value: 'image' },
        { text: 'SKU', value: 'sku' },
        { text: '名称', value: 'name' },
        { text: '变体', value: 'variant' },
        { text: '数量', value: 'qty' },
        { text: '单价', value: 'price' },
        { text: '金额', value: 'total' },
        { text: '发货', value: 'shipment' },
      ],
      refundHeaders: [
        { text: '申请时间', value: 'created_at' },
        { text: '申请原因', value: 'remark' },
        { text: '备注', value: 'comment' },
        { text: '申请状态', value: 'status' },
        { text: '操作', value: 'actions' },
      ],
      shipDialog: false,
      selectItems: [],
      trackingNo: null,
      shippingCode: null,
      trackingImg: null,
      shippingCompanies: [],
      shippingCompaniesLoading: false,
      deleteDialog: false,
    }
  },
  created() {
    this.orderId = Number(this.$route.params.id)
    this.getOrder()
  },
  methods: {
    async getOrder() {
      const res = await getOrder(this.orderId)
      this.row = res.data
    },
    async operateOrder(data) {
      await operateOrder(this.orderId, data)
      this.$toast.success('操作执行成功')
      this.$router.go(0)
    },
    openShipDialog() {
      this.shipDialog = true
      this.shippingCompaniesLoading = true
      getShipping().then(res => {
        this.shippingCompanies = res.data
        this.shippingCompaniesLoading = false
      })
    },
    cancelShip() {
      this.trackingNo = null
      this.shipDialog = false
    },
    selectFile(file) {
      if (!file) {
        this.previewImage = null
        return
      }
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = e => {
        this.previewImage = e.target.result
      }
    },
    async orderShip() {
      let img_url = null
      if (this.trackingImg) {
        const formData = new FormData()
        formData.append('image', this.trackingImg)
        const { data } = await uploadImage(formData)
        img_url = data.image
      }
      const itemIds = this.selectItems.map(item => item.id)
      await this.operateOrder({
        ship_items: itemIds,
        tracking_no: this.trackingNo,
        shipping_code: this.shippingCode,
        img: img_url,
        code: 2,
      })
    },
    viewTrackingImage(item) {
      if (item.img) {
        window.open(item.img, '_blank')
      }
    },
    deleteBtnClick() {
      this.deleteDialog = true
    },
    cancelDelete() {
      this.deleteDialog = false
    },
    async deleteItem() {
      await deleteOrder(this.orderId)
      this.$toast.success('删除成功')
      await this.$router.push({ name: 'orders' })
    },
    gotoKuaiDi(item) {
      window.open(`https://www.kuaidi100.com/chaxun?com=${item.shipping_code}&nu=${item.tracking_no}`)
    },
  },
}
</script>

<style scoped>

</style>
